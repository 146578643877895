.button {
  display: inline-block;
  color: var(--color-lines-lighter);
  border: 2px solid;
  border-color: var(--color-lines-darker);
  border-radius: 4px;
  background-color: var(--color-bg-lightest);

  margin: 8px 0px;
  padding: 0.5em 1.5em 0.25em 1.5em;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 700;
}

.button.block {
  width: 100%;
  display: block;
}

.button.disabled, .button.button.disabled:active {
  background: none;
  border: dashed;
  color: var(--color-lines-mid);
  border-color: var(--color-lines-mid);
}
.button.disabled:hover {
  cursor: not-allowed;
}

.button:focus-visible {
  background-color: var(--color-bg-darker);
  border-color: transparent;
  outline-style: solid;
  outline-width: 2px;
  outline-color: var(--color-lines-darker);
}

.button:active {
  border-style: inset;
}

.button.flashy {
  background: var(--color-bg-gradient-mid);
}
.button.flashy:hover, .button.flashy:active {
  background: var(--color-bg-gradient-darker);
}

.button.rounded {
  --button-height: 2.5em;

  margin: 0.35em 0;
  box-sizing: border-box;
  border: none;
  padding: 0 var(--button-height);
  height: var(--button-height);
  border-radius: var(--button-height);
}

.button.rounded:hover, .button.rounded:active {
  background-color: var(--color-bg-lighter);
}
