.divider {
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 16px 0;
}

/* .content { */
 /* height: 100px; */
/* } */

.divider:before, .divider:after {
  content: "";
  margin: 0px 4px;
  border: none;
  border-top: 1px solid;
  border-bottom: 1px solid;
  height: 0;
}

.divider.left:before {
  width: 1em;
}
.divider.left:after, .divider.center:after, .divider.center:before, .divider.right:before {
  flex: 1;
}
.divider.right:after {
  width: 1em;
}
