html, body {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root, .App {
  height: 100%;

  --color-bg-lightest: #777;
  --color-bg-lighter: #555;
  --color-bg-mid: #333;
  --color-bg-darker: #222;

  --color-bg-gradient-mid: linear-gradient(90deg,#f16522,#faae31);
  --color-bg-gradient-darker: linear-gradient(90deg,#ff5805,#ff9e00);

  --color-lines-lightest: #fff;
  --color-lines-lighter: #efefef;
  --color-lines-lightish: #ccc;
  --color-lines-mid: #aaa;
  --color-lines-darkish: #777;
  --color-lines-darker: #555;
  --color-lines-darkest: #333;

  --color-lines-danger: #df6f00;

  color: var(--color-lines-lightish);
}

.App h1,h2,h3 {
  color: var(--color-lines-lighter);
}
