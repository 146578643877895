.socialButton {
  display: inline-flex;
  align-items: center;
  font-size: 16px;
  width: 100%;
  height: 46px;
  margin: 4px 0;
  padding: 0;
  border: none;
}
.socialButton:active {
  border-style: inherit;
}

.socialButton:focus-visible {
  outline-color: var(--color-lines-lightest);
}

/* .socialButton span {
  margin: 6px;
} */

.appleButton {
  background-color: black;
  color: white;
  border: none;
  border-radius: calc(0.15 * 46px);
  font-family: "SF Pro Text","SF Pro Icons","Helvetica Neue",Helvetica,Arial,sans-serif;
  font-weight: 400;
}
.socialButton.appleButton img {
  width: 46px;
  height: 46px;
}

.googleButton {
  color: var(--color-lines-lightest);
  background: #4285f4;
  border-radius: 1px;
  font-family:"Roboto",arial,sans-serif;
  font-weight: 500;
}

.facebookButton {
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 600;
  background-color: #4080ff;
  color: white;
  border-radius: 3px;
}
.socialButton.facebookButton img {
  width: 34px;
  height: 34px;
  margin: 6px;
}

.socialButton.rounded {
  font-family: Helvetica;
  font-weight: 600;
  font-size: 1.15em;
}
.socialButton.rounded img {
  width: 1.3em;
  height: unset;
  margin: 0;
  margin-right: 0.75em;
}
.socialButton.rounded span {
  margin: 0;
  padding-top: 0.1em;
}

.disclaimers {
  color: var(--color-lines-darker)
}

.disclaimers a, .disclaimers a:visited {
  color: inherit;
}
.disclaimers a:hover, .disclaimers a:active {
  color: var(--color-lines-mid);
}
