.background {
  width: 100%;
  height: 100%;
  background-color: var(--color-bg-mid);
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  font-size: small;
  max-width: 300px;
  width: 90%;
  box-sizing: border-box;
}

.container img {
  margin: 0;
  display: block;
}
