div.container {
  margin: 8px 2px;
}

div.container input {
  width: 100%;
  box-sizing: border-box; /* needed for the em padding */
  color: var(--color-lines-lighter);
  padding: 0.5em 0.25em 0.25em 0.25em;
  background-color: var(--color-bg-lighter);
  border: 1px solid var(--color-lines-darker);
  border-radius: 4px;
}

div.container input:focus, div.container input:focus-visible {
  background-color: var(--color-bg-darker);

  outline-color: var(--color-lines-darker);
;
  outline-width: 2px;
  outline-style: solid;
}

div.validationIssue {
  border-radius: 4px;
  outline-style: solid;
  outline-width: 2px;
  outline-color: var(--color-lines-danger);
}
